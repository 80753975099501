<template>
  <div>
    <el-form :inline="true" :model="formInline" class="demo-form-inline">
      <!-- <el-form-item label="">
        <el-input
          v-model="formInline.name"
          placeholder="请输入任务名称"
        ></el-input>
      </el-form-item> -->
      <el-form-item>
        <!-- <el-button type="primary" @click="query">查询</el-button> -->
        <el-button type="primary" @click="add">充值</el-button>
      </el-form-item>
    </el-form>
    <el-table
      :data="tableData"
      border
      max-height="460"
      :header-cell-style="{ background: '#E5F0FF' }"
      style="width: 100%"
    >
      <el-table-column type="index" label="序号" width="60" />
      <el-table-column prop="account" label="用户" />
      <el-table-column prop="amount" label="金额" />
      <el-table-column prop="unit" label="单价" />
      <el-table-column prop="smsSum" label="数量" />
      <el-table-column prop="remark" label="充值后总条数" />
      <el-table-column prop="createTime" label="充值时间" />
      <!-- <el-table-column label="操作" align="center" width="220">
        <template #default="scope">
          <el-button size="small" type="primary" @click="edit(scope.row)"
            >编辑</el-button
          >
          <el-button size="small" type="danger" @click="remove(scope.row.id)"
            >删除</el-button
          >
        </template>
      </el-table-column> -->
    </el-table>
    <el-pagination
      background
      @size-change="sizeChangeHandler"
      @current-change="currentChangeHandler"
      :current-page="pagination.pageNo"
      :page-sizes="[10, 15, 20]"
      :page-size="pagination.length"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pagination.total"
    ></el-pagination>
    <!-- 新建模板 -->
    <el-dialog v-model="addDialog.isShow" title="新增记录" width="500px">
      <el-form
        :model="addDialog.addForm"
        ref="addForm"
        :rules="addDialog.rules"
        label-width="80px"
      >
        <el-form-item label="充值用户" prop="userId">
          <el-select
            v-model="addDialog.addForm.userId"
            placeholder="请选择充值用户"
          >
            <el-option
              v-for="(item, i) in usersList"
              :key="i"
              :label="item.account"
              :value="item.id"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="金额" prop="amount">
          <el-input
            v-model="addDialog.addForm.amount"
            @input="monetInput"
          ></el-input>
        </el-form-item>
        <el-form-item label="单价" prop="unit">
          <el-input
            v-model="addDialog.addForm.unit"
            @input="unitInput"
          ></el-input>
        </el-form-item>
        <el-form-item label="数量" prop="smsSum">
          <el-input readonly v-model="addDialog.addForm.smsSum"></el-input>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="addDialog.isShow = false">取消</el-button>
          <el-button type="primary" @click="addSave('addForm')">确定</el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "chargeList",
  data() {
    return {
      formInline: {},
      usersList: [],
      tableData: [],
      pagination: {
        total: 0, //总条数
        pageNo: 1, // 页数
        length: 15, // 每页的条数
      }, // 分页控件
      addDialog: {
        isShow: false,
        addForm: {
          userId: "",
          amount: null,
          unit: null,
          smsSum: null,
        },
        rules: {
          userId: [
            { required: true, message: "请选择充值用户", trigger: "blur" },
          ],
          amount: [{ required: true, message: "请输入金额", trigger: "blur" }],
          unit: [{ required: true, message: "请输入单价", trigger: "blur" }],
          smsSum: [{ required: true, message: "请输入数量", trigger: "blur" }],
        },
      },
    };
  },
  mounted() {
    this.getTableData();
  },
  watch: {
    "addDialog.isShow"(newVal) {
      if (!newVal) {
        this.$refs.addForm.resetFields();
        this.addDialog.addForm.userId = "";
        this.addDialog.addForm.amount = null;
        this.addDialog.addForm.unit = null;
        this.addDialog.addForm.smsSum = null;
      }
    },
  },
  methods: {
    // 获取列表数据
    getTableData() {
      this.$http
        .request("getRechargeList", {
          page: this.pagination.pageNo,
          limit: this.pagination.length,
        })
        .then((res) => {
          this.tableData = res.result.data;
          this.pagination.total = res.result.count;
        })
        .catch(() => {});
      this.$http
        .request("getUserList", {
          // page: this.pagination.pageNo,
          // limit: this.pagination.length,
        })
        .then((res) => {
          this.usersList = res.result.data;
          // this.pagination.total = res.result.count;
        })
        .catch(() => {});
    },
    // 查询
    query() {},
    // 新建模板
    add() {
      this.addDialog.isShow = true;
    },
    monetInput(val) {
      this.addDialog.addForm.amount = val;
      let smsSum = parseInt(
        this.addDialog.addForm.amount / this.addDialog.addForm.unit
      );
      if (this.isNaN(smsSum)) {
        this.addDialog.addForm.smsSum = "不是数字";
      } else {
        this.addDialog.addForm.smsSum = smsSum;
      }
    },
    unitInput(val) {
      this.addDialog.addForm.unit = val;
      let smsSum = parseInt(
        this.addDialog.addForm.amount / this.addDialog.addForm.unit
      );
      if (this.isNaN(smsSum)) {
        this.addDialog.addForm.smsSum = "不是数字";
      } else {
        this.addDialog.addForm.smsSum = smsSum;
      }
    },
    isNaN(value) {
      if (value != value) {
        // console.log("这是NaN");
        return true;
      } else {
        // console.log("这不是NaN");
        return false;
      }
    },
    // 新建保存
    addSave(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          if (this.addDialog.addForm.smsSum != "不是数字") {
            let postData = {
              id: this.addDialog.addForm.userId,
              amount: parseFloat(this.addDialog.addForm.amount),
              freeze: parseFloat(this.addDialog.addForm.unit),
              sum: parseInt(this.addDialog.addForm.smsSum),
            };
            this.$http
              .request("updateUser", postData)
              .then((res) => {
                this.$message({
                  type: "success",
                  message: "操作成功",
                });
                this.addDialog.isShow = false;
                this.getTableData();
              })
              .catch(() => {});
          } else {
            this.$message.error("您输入的金额或单价格式不对，请重新输入数字！");
          }
        }
      });
    },
    edit(row) {},
    remove(id) {},
    // 换每页条数
    sizeChangeHandler(length) {
      this.pagination.length = length;
      this.getTableData();
    },
    // 换页
    currentChangeHandler(pageNo) {
      this.pagination.pageNo = pageNo;
      this.getTableData();
    },
  },
};
</script>

<style lang="scss" scoped>
.el-pagination {
  margin-top: 30px;
  text-align: right;
}
</style>